import React, { useState } from "react";
import PropertyDetails from "./PropertyDetails";
import AcademicInformation from "./AcademicInformation";
import FamilyDetails from "./FamilyDetails";
import PersonalInformation from "./PersonalInformation";
import Information from "./Information";
import FinancialInformation from "./FinancialInformation";
import SiblingsScholarship from "./SiblingsScholarship";
import FormPreview from "./Preview";
import PDF from "./Pdfform";
import Header from "../Header/Header";
import QrCodeGeneratorAndScanner from "../QRCode/qrcode";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);

  // Separate state for personal information
  const [personalFormData, setPersonalFormData] = useState({});

  // State for other sections
  const [formData, setFormData] = useState({
    academic: {},
    finance: {},
    property: {},
    siblings: {},
    family: {},
    // Add more sections as needed
  });

  console.log(formData);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  // Function to update formData with new data for specific section
  const updateFormData = (section, newData) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        ...newData,
      },
    }));
  };

  const steps = [
    <Information nextStep={nextStep} />,
    <PersonalInformation
    formData={formData} // Pass formData directly
    setFormData={setFormData} // Update to setFormData
    nextStep={nextStep}
    prevStep={prevStep}
  />,
    <AcademicInformation
      formData={formData.academic}
      setFormData={(data) => updateFormData("academic", data)}
      nextStep={nextStep}
      prevStep={prevStep}
    />,
    <FamilyDetails
      formData={formData.family}
      setFormData={(data) => updateFormData("family", data)}
      nextStep={nextStep}
      prevStep={prevStep}
    />,
    <FinancialInformation
      formData={formData.finance}
      setFormData={(data) => updateFormData("finance", data)}
      nextStep={nextStep}
      prevStep={prevStep}
    />,
    <PropertyDetails
      formData={formData.property}
      setFormData={(data) => updateFormData("property", data)}
      nextStep={nextStep}
      prevStep={prevStep}
    />,
    <SiblingsScholarship
      formData={formData.siblings}
      setFormData={(data) => updateFormData("siblings", data)}
      nextStep={nextStep}
      prevStep={prevStep}
    />,
    <FormPreview formData={formData} nextStep={nextStep} prevStep={prevStep} />,
    <PDF formData={formData} nextStep={nextStep} prevStep={prevStep} />,
    <QrCodeGeneratorAndScanner formData={formData} />,
  ];

  return (
    <>
      <div className="flex mt-6">
        <Header />
      </div>
      <div className="flex justify-center m-4 md:px-8 items-center">
        <div className="w-full max-w-4xl p-4 md:p-8 bg-gray-200 shadow-md rounded-lg">
          {steps[step - 1]}
        </div>
      </div>
    </>
  );
};

export default MultiStepForm;
