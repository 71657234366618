import React from "react";
import Swal from "sweetalert2";
import {
  FiHome,
  FiUsers,
  FiSettings,
  FiMenu,
  FiX,
  FiBook,
  FiMap,
  FiClipboard,
  FiLogOut,
} from "react-icons/fi";

const Sidebar = ({ selectedMenu, handleMenuClick, isOpen, toggleSidebar }) => {
  
  const sidebarItems = [
    { title: "Dashboard", icon: FiHome },
    { title: "Universities", icon: FiBook },
    { title: "Districts", icon: FiMap },
    { title: "Discipline", icon: FiClipboard },
    { title: "Users", icon: FiUsers },
    { title: "Settings", icon: FiSettings },
  ];

  const handleClick = (menu) => {
    if (menu === 'Logout') {
      Swal.fire({
        title: 'Confirm Logout',
        text: 'Are you sure you want to logout?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Logout',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          handleLogoutConfirm();
        }
      });
    } else {
      handleMenuClick(menu);
    }
  };
  
  const handleLogoutConfirm = () => {
    // Redirect to the form-seef.com link
    window.location.href = 'https://form-seef.com';
  };
  

  return (
    <div
      className={`bg-gray-600 text-white fixed h-full left-0 top-0 ${
        isOpen ? "w-56" : "w-16"
      }`}
    >
      <div className="p-4 flex flex-col h-full mt-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className={`text-xl font-bold ${isOpen ? "" : "hidden"}`}>
            Admin Panel
          </h1>
          <button onClick={toggleSidebar} className="text-gray-300">
            {isOpen ? (
              <FiX className="w-6 h-6" />
            ) : (
              <FiMenu className="w-6 h-6" />
            )}
          </button>
          {!isOpen && (
            <button
              onClick={toggleSidebar}
              className="absolute right-4 top-4 text-gray-300 md:hidden"
            >
              {/* <FiMenu className="w-6 h-6" /> */}
            </button>
          )}
        </div>
        <div className="mb-4 py-4 border-t border-gray-500">
          <ul>
            {sidebarItems.slice(0, 4).map((item, index) => (
              <li
                key={index}
                className={`flex justify-between items-center mb-6 ${
                  selectedMenu === item.title ? "" : ""
                }`}
                onClick={() => handleClick(item.title)}
              >
                <span className={`ml-2 ${isOpen ? "" : "hidden"}`}>
                  {item.title}
                </span>
                <item.icon className="w-6 h-6" />
              </li>
            ))}
          </ul>
        </div>
        <hr className="border-gray-500 my-4" />
        <div className="mt-auto mb-4">
          <ul>
            {sidebarItems.slice(4, 6).map((item, index) => (
              <li
                key={index}
                className={`flex justify-between items-center mb-6 ${
                  selectedMenu === item.title ? "bg-gray-700" : ""
                }`}
                onClick={() => handleClick(item.title)}
              >
                <span className={`ml-2 ${isOpen ? "" : "hidden"}`}>
                  {item.title}
                </span>
                <item.icon className="w-6 h-6" />
              </li>
            ))}
            <li
              className="flex justify-between items-center mb-6 cursor-pointer"
              onClick={() => handleClick("Logout")}
            >
              <span className={`ml-2 ${isOpen ? "" : "hidden"}`}>Logout</span>
              <FiLogOut className="w-6 h-6" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
